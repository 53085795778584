import React from 'react';
import './footer.css';
import { FaFacebookF } from 'react-icons/fa';
import { FiInstagram } from 'react-icons/fi';
import { IoLogoTwitter } from 'react-icons/io';
import Logo from '../../assets/logo.svg';

const Footer = () => {
    return (
        <footer>
            <a href='/#' className='footer__logo'>
                <img src={Logo} alt='TD' className='footer__logo-image' />
            </a>

            <ul className='permalinks'>
                <li>
                    <a href='/#'>Home</a>
                </li>
                <li>
                    <a href='#about'>About</a>
                </li>
                <li>
                    <a href='#experience'>Experience</a>
                </li>
                <li>
                    <a href='#services'>Services</a>
                </li>
                <li>
                    <a href='#portfolio'>Portfolio</a>
                </li>
                <li>
                    <a href='#testimonials'>Testimonials</a>
                </li>
                <li>
                    <a href='#contact'>Contact</a>
                </li>
                <li>
                    <a href='#about'>About</a>
                </li>
            </ul>

            <div className='footer__socials'>
                <a
                    href='https://www.facebook.com/tobin.dekorne/'
                    rel='noreferrer'
                    target='_blank'
                >
                    <FaFacebookF />
                </a>
                <a
                    href='https://www.instagram.com/tobindekorne/'
                    rel='noreferrer'
                    target='_blank'
                >
                    <FiInstagram />
                </a>
                <a
                    href='https://twitter.com/TdeKorne'
                    rel='noreferrer'
                    target='_blank'
                >
                    <IoLogoTwitter />
                </a>
            </div>

            <div className='footer__copyright'>
                <small>
                    &copy; Tobin deKorné {new Date().getFullYear()}. All rights
                    reserved.
                </small>
            </div>
        </footer>
    );
};

export default Footer;
